



























































import { Component, Vue, Watch } from 'vue-property-decorator'
import SelectButtonGroup from '@/components/forms/SelectButtonGroup.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import Modal from '@/components/Modal.vue'

const AccountLog = () => import('./ProfileDetails/AccountLog.vue')
const DocumentList = () => import('./ProfileDetails/DocumentList.vue')
const TariffDetails = () => import('./ProfileDetails/TariffDetails.vue')
const InvoiceList = () => import('./ProfileDetails/InvoiceList.vue')

@Component({
  name: 'ProfileDetails',
  components: {
    SelectButtonGroup,
    BaseButton,
    Modal,
    AccountLog,
    DocumentList,
    TariffDetails,
    InvoiceList,
  },
})
export default class ProfileDetails extends Vue {
  showSuccess = false
  showError = false
  items = [
    { id: 'AccountLog', title: 'Счет', tab: '' },
    { id: 'DocumentList', title: 'Документы', tab: 'documents' },
    { id: 'TariffDetails', title: 'Тариф', tab: 'tariff' },
    { id: 'InvoiceList', title: 'Заказы', tab: 'invoices' },
  ]
  selected = 'AccountLog'

  created(): void {
    this.setTabs()
  }
  beforeUpdate(): void {
    this.$emit('change-tab')
  }

  setTabs(): void {
    const { tab, payment } = this.$route.query
    if (tab) {
      const current = this.items.find((item) => item.tab === tab)?.id
      if (current) this.selected = current
    }
    if (payment === 'success') {
      this.showSuccess = true
    }
    if (payment === 'failure') {
      this.showError = true
    }
  }

  onSelectTab(val: string): void {
    if (val) {
      const query = Object.assign({}, this.$route.query)
      delete query.tab
      const current = this.items.find((item) => item.id === val)?.tab
      if (current) query.tab = current
      this.$router.replace({ query })
    }
  }

  onCloseModal(): void {
    const query = Object.assign({}, this.$route.query)
    delete query.payment
    this.$router.replace({ query })
  }

  @Watch('tab')
  onTabChange(val: string): void {
    if (val) {
      this.setTabs()
    }
  }

  get tab(): string {
    return String(this.$route.query.tab)
  }
}
